import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  selectIsLocalVideoEnabled,
  selectLocalPeer,
  selectVideoTrackByID,
  useAVToggle,
  useHMSStore,
  usePreviewJoin,
} from "@100mslive/react-sdk";
import { SettingsIcon } from "@100mslive/react-icons";
import {
  Avatar,
  Flex,
  flexCenter,
  Loading,
  styled,
  StyledVideoTile,
  Text,
  textEllipsis,
  useBorderAudioLevel,
  useTheme,
  Video,
} from "@100mslive/react-ui";
import IconButton from "../../IconButton";
import { AudioVideoToggle } from "../AudioVideoToggle";
import TileConnection from "../Connection/TileConnection";
import SettingsModal from "../Settings/SettingsModal";
import PreviewName from "./PreviewName";
import { VirtualBackground } from "../../plugins/VirtualBackground/VirtualBackground";
import { useUISettings } from "../AppData/useUISettings";
import {
  defaultPreviewPreference,
  UserPreferencesKeys,
  useUserPreferences,
} from "../hooks/useUserPreferences";
import { UI_SETTINGS } from "../../common/constants";

const PreviewJoin = ({
  token,
  onJoin,
  env,
  skipPreview,
  initialName,
  asRole,
}) => {
  const { roomId, role,meetingId } = useParams(); // from the url
  const [previewPreference, setPreviewPreference] = useUserPreferences(
    UserPreferencesKeys.PREVIEW,
    defaultPreviewPreference
  );
  const [name, setName] = useState(initialName || previewPreference.name);
  const { isLocalAudioEnabled, isLocalVideoEnabled } = useAVToggle();
  const [previewError, setPreviewError] = useState(false);
  const[meetingData,setMeetingData]=useState("");
  
  const getApiData = async () => {
    const response = await fetch(
      process.env.API_SERVICE_URL+'/api/meeting/getMeetingbyId/'+meetingId
    ).then((response) => response.json());
    setMeetingData(response?.data);
  };

  useEffect(()=>{
    getApiData();
  },[])

  const { enableJoin, preview, join } = usePreviewJoin({
    name,
    token,
    initEndpoint: env ? `https://${env}-init.100ms.live/init` : undefined,
    initialSettings: {
      isAudioMuted: skipPreview || previewPreference.isAudioMuted,
      isVideoMuted: skipPreview || previewPreference.isVideoMuted,
      speakerAutoSelectionBlacklist: ["Yeti Stereo Microphone"],
    },
    captureNetworkQualityInPreview: true,
    handleError: (_, method) => {
      if (method === "preview") {
        setPreviewError(true);
      }
    },
    asRole,
  });
  const savePreferenceAndJoin = useCallback(() => {
    setPreviewPreference({
      name,
      isAudioMuted: !isLocalAudioEnabled,
      isVideoMuted: !isLocalVideoEnabled,
    });
    join();
    onJoin && onJoin();
  }, [
    join,
    isLocalAudioEnabled,
    isLocalVideoEnabled,
    name,
    setPreviewPreference,
    onJoin,
  ]);
  useEffect(() => {
    if (token) {
      if (skipPreview) {
        savePreferenceAndJoin();
      } else {
        preview();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, skipPreview]);
  return (<div style={{ display: "flex", marginTop: '66px', width: '50%' }}>

    <Container>

      <div className="col-md-12">
       { meetingData ?
        <div className="vcall-sectiona" style={{ backgroundColor: '#F4F6FC', textAlign: 'center', paddingTop: 30 }}>
          <img src={meetingData?.createdBy?.profileImagePath??"https://bootstrapmade.com/demo/templates/Mentor/assets/img/trainers/trainer-2.jpg"} style={{ height: "150px", width: "150px", borderRadius: 100, marginLeft: 'auto', marginRight: 'auto' }}></img>
          <p className="caller-name mb-0" style={{
            textAlign: 'center',
            marginTop: 5,
            fontSize: 16,
            fontWeight: 600,
            paddingBottom: 5
          }}>{meetingData?.createdBy?.userName}</p>
          <a className="host-btn" style={{ backgroundColor: '#FFCB33', color: '#000', paddingTop: "2px", paddingBottom: "2px", paddingRight: "7px", paddingLeft: "7px" }}>{role}</a>
          <div className="mt-7">
            <p className="mb-0 caller-name" style={{
              textAlign: 'center',
              marginTop: 18,
              fontSize: 16,
              fontWeight: 600
            }}>{meetingData?.discussionType?.name}</p>
            <p className="caller-name mt-0" style={{
              textAlign: 'center',
              marginTop: 8,
              fontSize: 16,
              fontWeight: 600
            }}>{meetingData?.fromTime} -{meetingData?.toTime} ({meetingData?.duration})m</p>
          </div>
          <div className="">
            <p className="gigpro mb-2" style={{
              marginTop: 20,
              fontSize: 14,
              fontWeight: 600
            }}>{meetingData?.reqNo}</p>
            <a className="arng-btn" style={{ backgroundColor: '#D8781F', color: '#fff', paddingTop: "2px", paddingBottom: "2px", paddingRight: "7px", paddingLeft: "7px" }}>{meetingData?.projectType?.name}</a>
          </div>
          <div className="vcall-ftr mt-7" style={{ backgroundColor: '#718FFA', padding: 20, color: '#fff', marginTop: 50, }}>
            <p className="mb-2">You will be able to join the meeting by</p>
            <p className="mb-0 vtime" style={{ fontWeight: 600, fontSize: 16 }}>{meetingData?.fromTime} IST</p>
          </div>
        </div>:  <div className="vcall-sectiona">Loading....</div> 
         }
      </div>
    </Container>

    <Container>
      <div className="col-md-12">
        <Text variant="h4" css={{ wordBreak: "break-word", textAlign: "center" }}>
          Get Started
        </Text>
        <Text
          css={{ c: "$textMedEmp", my: "$6", textAlign: "center", marginTop: 0, marginBottom: 0 }}
          variant="body1">
          Setup your audio and video before joining
        </Text>
        <Flex
          align="center"
          justify="center"
          css={{
            "@sm": { width: "100%" },
            flexDirection: "column",
          }}
        >
          <PreviewTile name={name} error={previewError} />
          <PreviewControls
            enableJoin={enableJoin}
            savePreferenceAndJoin={savePreferenceAndJoin}
          />
          <PreviewName
            name={name}
            onChange={setName}
            enableJoin={enableJoin}
            onJoin={savePreferenceAndJoin}
          />
        </Flex>
      </div>
    </Container>
  </div>
  );
};

const Container = styled("div", {
  width: "50%",
  paddingLeft: "2em",
  paddingRight: "2em",
  //  ...flexCenter,
  //flexDirection: "column",
  //px: "$10",
});

const PreviewTile = ({ name, error }) => {
  const localPeer = useHMSStore(selectLocalPeer);
  const borderAudioRef = useBorderAudioLevel(localPeer?.audioTrack);
  const isVideoOn = useHMSStore(selectIsLocalVideoEnabled);
  const mirrorLocalVideo = useUISettings(UI_SETTINGS.mirrorLocalVideo);
  const trackSelector = selectVideoTrackByID(localPeer?.videoTrack);
  const track = useHMSStore(trackSelector);

  const {
    aspectRatio: { width, height },
  } = useTheme();
  return (
    <StyledVideoTile.Container
      css={{
        bg: "$surfaceDefault",
        aspectRatio: width / height,
        width: "unset",
        height: "min(360px, 60vh)",
        mt: "$12",
        "@sm": {
          height: "unset",
          width: "min(360px, 100%)",
          maxWidth: "100%",
        },
      }}
      ref={borderAudioRef}
    >
      {localPeer ? (
        <>
          <TileConnection name={name} peerId={localPeer.id} hideLabel={true} />
          <Video
            mirror={track?.facingMode !== "environment" && mirrorLocalVideo}
            trackId={localPeer.videoTrack}
            data-testid="preview_tile"
          />
          {!isVideoOn ? (
            <StyledVideoTile.AvatarContainer>
              <Avatar name={name} data-testid="preview_avatar_tile" />
              <Text css={{ ...textEllipsis("75%") }} variant="body2">
                {name}
              </Text>
            </StyledVideoTile.AvatarContainer>
          ) : null}
        </>
      ) : !error ? (
        <Loading size={100} />
      ) : null}
    </StyledVideoTile.Container>
  );
};

const PreviewControls = () => {
  return (
    <Flex
      justify="between"
      css={{
        width: "100%",
        mt: "$8",
      }}
    >
      <Flex css={{ gap: "$4" }}>
        <AudioVideoToggle compact />
        <VirtualBackground />
      </Flex>
      <PreviewSettings />
    </Flex>
  );
};

const PreviewSettings = React.memo(() => {
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <IconButton
        data-testid="preview_setting_btn"
        onClick={() => setOpen(value => !value)}
      >
        <SettingsIcon />
      </IconButton>
      {open && <SettingsModal open={open} onOpenChange={setOpen} />}
    </Fragment>
  );
});

export default PreviewJoin;
